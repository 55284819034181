<template>
  <div class="mb-3">
    <p class="form-label text-muted" v-if="label">{{ label }}</p>
    <div
      class="form-check"
      v-for="(item, index) in options"
      :key="'option_' + index"
    >
      <label
        class="form-check-label text-primary d-flex justify-content-between py-3"
        :for="'option_' + index"
      >
        {{ item.name || item.title }}
        <input
          class="form-check-input"
          type="checkbox"
          :value="item.id"
          v-model="model.$model"
          :id="'option_' + index"
          @change="onChange($event)"
        />
      </label>
      <hr class="m-0 p-0" v-if="index + 1 !== options.length" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      required: false,
      default: () => {},
    },
    label: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onChange(event) {
      this.$emit("onChange", event.target.value);
    },
  },
};
</script>

<style scoped>
.form-check {
  padding-left: unset;
}
.form-check-input {
  margin-left: unset;
  float: right;
}
.form-check-input:checked {
  background-color: #fba486;
}
hr {
  border-color: #f2f2f2;
}
.form-check-input:focus:not(:checked) {
  background-color: #e5e5e5;
}
</style>

<!-- <template>
  <div>
    <p>
      <span v-if="item.specialties.includes(sp.id)"
        class="fa-solid fa-circle-check fa-medium-size mb-0 text-muted"></span>
      <span v-else class="far fa-circle fa-medium-size mb-0 text-muted"></span>
      {{sp.title}}
    </p>
  </div>
</template>

<script>
export default {
  name: 'FormButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Field name'
    }
  },
  methods: {
    clicked () {
      this.$emit('onClick')
    }
  }
}
</script> -->
