export function sortCols(columns: Array<any>, colIndex: string | number) {
    try {
        return columns.map((r, index) => {
            let order = null
            let loading = false
            if (colIndex == index) {
                loading = true
                order = r.order == null ? 'ASC' : r.order == 'ASC' ? 'DESC' : null
            }
            const l = {
                ...r,
                order,
                loading

            }
            return l
        })
    } catch (e) {
        console.log(e)
        return columns
    }

}
export function dataURLtoFile(dataurl: string, filename: string): File {

    var arr = dataurl.split(',')
    var type
    var mime: any = arr[0].match(/:(.*?);/)
    type = mime[1]
    var bstr = atob(arr[1])
    var n = bstr.length
    var u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type });

}

export function replacePhoneNumberToHash(text: string) {
    let res = "";

    if (text) {
        for (let i = 0; i < text.length; i++) {
            if (text[i] === "9") {
                res += "#";
            } else {
                res += text[i];
            }
        }
    }

    return res;
};

export function toPattern(value: string, mode = "cpf", customPattern = "") {
    let newString = "";
    let stringIndex = 0;
    try {
        let pattern = Array(value.length).fill("#").join();

        switch (mode) {
            case "cpf":
                pattern = "###.###.###-##";
                break;
            case "phone":
                pattern = customPattern;
                break;
        }
        const patternCount = pattern.length;
        for (var i = 0; i < patternCount; i++) {
            if (value[stringIndex]) {
                if (pattern[i] == "#") {
                    newString = `${newString}${value[stringIndex]}`;
                    stringIndex++;
                } else {
                    newString = `${newString}${pattern[i]}`;
                }
            }
        }
    } catch (e) {
        return value;
    } finally {
        return newString;
    }
};

export function toggleModal(id: string) {
    const button = document.getElementById(`${id}_modal_button`)
    if (button) {
        button?.click()
    }
}