<template>
  <nav
    class="navbar navbar-expand navbar-light d-none d-md-flex px-6 ps-0"
    id="topbar"
  >
    <div class="container-fluid">
      <router-link :to="$paths.home">
        <img src="@/assets/img/sidebar_logo.svg" alt="" />
      </router-link>

      <nav aria-label="breadcrumb" class="me-auto ms-4">
        <ol class="breadcrumb fw-bold">
          <router-link v-slot="{ navigate }" custom v-if="link" :to="link">
            <li
              @click="navigate"
              class="breadcrumb-item d-flex align-items-center"
            >
              <a>
                {{ linkName }}
              </a>
            </li>
          </router-link>
          <router-link
            v-slot="{ navigate }"
            custom
            v-if="sublink"
            :to="sublink"
          >
            <li
              @click="navigate"
              class="breadcrumb-item d-flex align-items-center"
            >
              <a>{{ sublinkName }}</a>
            </li>
          </router-link>
          <li
            class="breadcrumb-item active d-flex align-items-center"
            aria-current="page"
          >
            {{ current }}
          </li>
        </ol>
      </nav>

      <div class="navbar-user" v-if="userSession">
        <template v-if="userSession.approved">
          <ul class="navbar-nav">
            <router-link
              active-class="router-link-active"
              :to="$paths.appointments.list"
            >
              <li class="nav-item">
                <a class="nav-link text-paragraph">Agenda</a>
              </li>
            </router-link>
            <router-link
              :to="`${$paths.more}/minha-conta`"
              :class="{ 'router-link-active': moreLinkActive }"
            >
              <li class="nav-item">
                <a class="nav-link text-paragraph">Mais</a>
              </li>
            </router-link>
          </ul>
          <span class="ps-4 pe-3 text-paragraph text-muted">|</span>
        </template>
        <div
          class="dropdown cursor-pointer align-items-center d-flex"
          @mouseover="avatarOver"
          @mouseleave="avatarLeave"
        >
          <span class="text-subtitle me-3">{{ userSession?.name }}</span>
          <a href="#" class="avatar avatar-sm dropdown-toggle">
            <img
              v-if="userSession?.profile_image"
              :src="userSession?.profile_image"
              alt=""
              width="40"
              height="40"
              class="rounded-circle"
            />
            <i v-else class="fa-solid fa-user-circle display-3 text-muted"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-end cursor-pointer">
            <span @click="logout" class="dropdown-item text-danger">Sair</span>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { store } from "@/store";
import $ from "jquery";
export default {
  name: "Navbar",
  props: {
    link: String,
    linkName: String,
    sublink: String,
    sublinkName: String,
    current: String,
  },
  computed: {
    userSession() {
      return store.state.session;
    },
    moreLinkActive() {
      return this.$route.path.includes("mais");
    },
  },
  methods: {
    async logout() {
      this.$router.push(this.$paths.login);
    },
    avatarOver() {
      $(".dropdown-menu").addClass("show");
      $(".dropdown-menu").attr("data-bs-popper", true);
    },
    avatarLeave() {
      $(".dropdown-menu").removeClass("show");
      $(".dropdown-menu").removeAttr("data-bs-popper");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar {
  padding-left: 250px !important;
}
</style>
