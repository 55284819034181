
import { createStore, Store, useStore } from "vuex"
import { InjectionKey } from "vue"
import INotification from "@/interfaces/INotification"
import ISession from '@/interfaces/ISession'
interface State {
  session: ISession | null
  notifications: INotification[]
  token: string | null
}
export const key: InjectionKey<Store<State>> = Symbol()
export function customUseStore(): Store<State> {
  return useStore(key)
}

export enum MUTATIONS {
  SAVE_SESSION = 'SAVE_SESSION',
  SAVE_TOKEN = 'SAVE_TOKEN',
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  CLEAR_SESSION = 'CLEAR_SESSION'
}

export function getSessionFromStorage(): ISession | null {
  const localSession = localStorage.getItem(process.env.VUE_APP_API_SESSION_KEY)
  if (!localSession) return null
  const s: any = JSON.parse(localSession)
  const session: ISession = {
    id: s.id,
    name: s.name,
    email: s.email,
    created_at: s.created_at,
    type: s.type,
    profile_image: s.profile_image,
    approved: s.approved,
    stripe_id: s.stripe_id,
    charges_enabled: s.charges_enabled,
    account_pending_verification: s.account_pending_verification,
    account_verification_errors: s.account_verification_errors,
    country_code: s.country_code,
    phone: s.phone,
    formattedPhone: s.formattedPhone,
    subscription: s.subscription,
    favorite_card: s.favorite_card,
    entry_fee: s.entry_fee
  }
  return session
}
export function getTokenFromStorage(): string | null {
  return localStorage.getItem(process.env.VUE_APP_API_TOKEN_KEY)
}

export const store = createStore<State>({
  state: {
    session: getSessionFromStorage(),
    notifications: [],
    token: getTokenFromStorage()
  },
  mutations: {
    [MUTATIONS.SAVE_SESSION](state, session: ISession) {
      state.session = session
      localStorage.setItem(process.env.VUE_APP_API_SESSION_KEY, JSON.stringify(session))
    },
    [MUTATIONS.SAVE_TOKEN](state, token: string) {
      state.token = token
      localStorage.setItem(process.env.VUE_APP_API_TOKEN_KEY, token)
    },
    [MUTATIONS.CLEAR_SESSION](state) {
      localStorage.removeItem(process.env.VUE_APP_API_SESSION_KEY)
      localStorage.removeItem(process.env.VUE_APP_API_TOKEN_KEY)
      localStorage.removeItem(process.env.VUE_APP_USER_INFO_KEY)
      localStorage.removeItem(process.env.VUE_APP_REGISTER_FORM_KEY)
      state.session = null
      state.token = null
    },
    [MUTATIONS.ADD_NOTIFICATION](state, notification: INotification) {
      notification.id = new Date().getTime()
      state.notifications.push(notification)
      setTimeout(() => {
        state.notifications = state.notifications.filter((not) => not.id != notification.id)
      }, 3000)
    },
  },
})
