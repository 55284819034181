import { store } from "@/store";
import { toggleModal } from "@/utils/Helper";
import { defineComponent } from "vue";

export default defineComponent({
    mounted() {
        if (this.userSession) {
            this.loading = true
            this.getCreditCards()
            this.getMySubs()
            this.$eventBus.$on('getCreditCards', () => this.getCreditCards())
        }
    },
    data() {
        return {
            loading: false,
            items: [],
            mySub: null as any
        }
    },
    computed: {
        userSession() {
            return store.state.session
        }
    },
    methods: {
        async getCreditCards() {
            try {
                const res = await this.$paymentService.getCreditCards()
                this.items = res.data
            } catch (error: any) {
                console.log(error)
                alert(error?.message)
            }
        },
        async handleSelectCard(card: any) {
            try {
                if (card.card_id == this.userSession?.favorite_card?.card_id) return
                this.$paymentService.setFavoriteCard(card)
                if (this.userSession?.subscription) {
                    await this.$subscriptionService.update(this.userSession?.subscription?.id, {
                        card_id: card?.card_id,
                    });
                    this.$userService.syncSession();
                }
                toggleModal('credit_cards_modal')
                this.$eventBus.$emit('refreshMyPlan')
            } catch (error: any) {
                console.log(error)
                alert(error?.message)
            }
        },
        handleDeleteCard(card: any) {
            if (
                this.mySub &&
                this.mySub.isActive &&
                this.mySub?.card_info?.id == card.card_id
            ) {
                alert(
                    "Não é possível remover este cartão pois ele está vinculado a sua assinatura ativa. Para remover este cartão é necessário cancelar sua assinatura ativa"
                );
                return;
            } else {
                this.$eventBus.$emit("showConfirmationAlert", {
                    title: 'Remover cartão',
                    message: `Tem certeza que deseja remover o cartão •••• •••• •••• ${card.last_digits}?`,
                    confirmCallback: async () => {
                        this.handleConfirmDeleteCard(card)
                    },
                    confirmationButtonLabel: 'Sim, remover'
                })
            }
        },
        async handleConfirmDeleteCard(card: any) {
            try {
                // setRemoveCardLoading(true);

                await this.$paymentService.removeCard(card.card_id);
                const remainingCards = this.items.filter(
                    (elem: any) => elem.card_id != card.card_id
                );

                this.items = remainingCards


                if (card.card_id == this.userSession?.favorite_card?.card_id) {
                    if (remainingCards.length) {
                        this.handleSelectCard(remainingCards[0]);
                    } else {
                        this.handleSelectCard(null);
                    }
                }

                this.$eventBus.$emit("showToast", {
                    title: "Cartão removido",
                    msg: `Seu cartão foi removido com sucesso`,
                });
            } catch (error: any) {
                console.log(error);
                alert(error?.message)
            }
        },
        handleAddCreditCard() {
            toggleModal('add_credit_card_modal')
        },
        async getMySubs() {
            try {
                const subscription = this.userSession?.subscription;

                if (subscription?.id) {
                    let res = await this.$subscriptionService.show(subscription?.id);
                    let data = this.$subscriptionService.prepareSubscriptionInfo(res.data);
                    this.mySub = data;
                } else {
                    this.mySub = null;
                }
            } catch (error: any) {
                console.log("ERRO NO GET PLAN DETAILS NA MY PLAN SCREEN", error);
                // alert(error.message);
            } finally {
                this.loading = false
            }
        },
        closeModal() {
            toggleModal('credit_cards_modal')
        }
    }
})