
import moment, { min } from 'moment'
import NetworkService, { Endpoint } from './NetworkService'
import qs from 'qs'

export default class AppointmentService {
    private network
    constructor() {
        this.network = new NetworkService()
    }

    static PAYOUT_STATUS = {
        PAID: "paid",
        FAILED: "failed",
        CANCELED: "canceled",
        IN_TRANSIT: "in_transit",
        PENDING: "pending",
    };

    static TYPES = {
        IMEDIATE: "imediate",
        SCHEDULED: "scheduled",
    };

    static STATUS = {
        AGUARDANDO: "aguardando",
        AGENDADO: "agendado",
        CONCLUIDO: "concluido",
        CANCELADO: "cancelado",
        RECUSADO: "recusado",
        ANDAMENTO: "em andamento",
    };

    static RESCHEDULE_STATUS = {
        PENDING: "pending",
        CANCELED: "canceled",
        ACCEPTED: "accepted",
        REJECTED: "rejected",
    };
    static MINIMUM_PRICE = 50;

    create = (params: any) => {
        return this.network.post(Endpoint.appointments, params)
    }
    list = (params: any) => {
        return this.network.get(Endpoint.appointments, params)
    }
    show = (id: any) => {
        return this.network.get(`${Endpoint.appointments}/${id}`)
    }
    update = (id: any, params: any) => {
        return this.network.put(`${Endpoint.appointments}/${id}`, params)
    }
    delete = (id: any) => {
        return this.network.delete(`${Endpoint.appointments}/${id}`)
    }
    createEvent = (params: any) => {
        return this.network.post(Endpoint.callEvents, params);
    };
    prepareAppointment(data: any) {
        let date = moment(data.date)
        data.formatted_date = `${date.format('DD/MM')} - ${date.format('dddd').capitalizeFirst()}`
        data.formatted_time = `das ${date.format('HH[h]mm')} às ${date.add(50, 'minutes').format('HH[h]mm')}`

        data.formatted_type = data?.type == AppointmentService.TYPES.IMEDIATE ? 'Imediata' : 'Agendada'

        data.isCancelable = data.status == AppointmentService.STATUS.AGENDADO

        // let statusConfig = {
        //     'aguardando': {
        //         statusLabel: 'Aguardando',
        //         statusColor: 'text-secondary',
        //         showButton: true,
        //     },
        //     'em andamento': {
        //         statusLabel: 'Em andamento',
        //         statusColor: 'text-info',
        //         showButton: true,
        //     },
        //     'concluido': {
        //         statusLabel: 'Finalizado',
        //         statusColor: 'text-muted',
        //         showButton: false,
        //     },
        //     'agendado': {
        //         statusLabel: 'Confirmado',
        //         statusColor: 'text-success',
        //         showButton: true,
        //     },
        //     'cancelado': {
        //         statusLabel: 'Cancelado',
        //         statusColor: 'text-danger',
        //         showButton: false,
        //     },
        //     'recusado': {
        //         statusLabel: 'Recusado',
        //         statusColor: 'text-danger',
        //         showButton: false,
        //     },
        // }

        switch (data.status) {
            case AppointmentService.STATUS.AGUARDANDO:
                data.statusLabel = 'Aguardando'
                data.statusColor = 'text-secondary'
                data.showButton = true
                break;
            case AppointmentService.STATUS.ANDAMENTO:
                data.statusLabel = 'Em andamento'
                data.statusColor = 'text-info'
                data.showButton = true
                break;
            case AppointmentService.STATUS.CONCLUIDO:
                data.statusLabel = 'Finalizado'
                data.statusColor = 'text-muted'
                data.showButton = false
                break;
            case AppointmentService.STATUS.AGENDADO:
                data.statusLabel = 'Confirmado'
                data.statusColor = 'text-success'
                data.showButton = true
                break;
            case AppointmentService.STATUS.CANCELADO:
                data.statusLabel = 'Cancelado'
                data.statusColor = 'text-danger'
                data.showButton = false
                break;
            case AppointmentService.STATUS.RECUSADO:
                data.statusLabel = 'Recusado'
                data.statusColor = 'text-danger'
                data.showButton = false
                break;
            default:
                break;
        }

        if (data.call_link) {
            const metadata = `${navigator.userAgent}|psi-pro-web|no-model|${process.env.VUE_APP_VERSION_STRING}|no-os-version`

            const wherebyConfig = {
                floatSelf: "",
                minimal: "",
                lang: "pt",
                skipMediaPermissionPrompt: "",
                chat: "on",
                people: "off",
                displayName: data.psychologist?.name,
                avatarUrl: process.env.VUE_APP_IMG_BASE_URL + data.psychologist?.profile_image,
                externalId: data.psychologist?.id,
                metadata,
            };

            data.callLink = `${data?.call_link}?${qs.stringify(wherebyConfig)}`;
        }

        if (data.user) {
            data.user.profileImage = require(`@/assets/img/avatars/${data?.user?.profile_image}.png`)
        }

        if (data.payout) {
            if (data?.payout?.main_payout_info) {
                data.payout = {
                    ...data.payout,
                    ...this.preparePayout(data?.payout?.main_payout_info)
                }
            }
        }

        return data
    }

    preparePayout(data: any) {
        let statusConfig: any = {
            'paid': {
                statusLabel: "Realizado",
                statusColor: "text-success",
            },
            'failed': {
                statusLabel: "Falha no repasse",
                statusColor: "text-warning",
            },
            'canceled': {
                statusLabel: "Cancelado",
                statusColor: "text-danger",
            },
            'in_transit': {
                statusLabel: "Em trânsito",
                statusColor: "text-primary50",
            },
            'pending': {
                statusLabel: "Aguardando",
                statusColor: "text-primary50",
            },
        }

        return {
            ...statusConfig[data.status],
            arrivalDate: moment(data?.arrival_date).format("DD/MM/YYYY"),
        }

    }
}