export default [
    {
        name: "Brasil",
        flag: "🇧🇷",
        code: "BR",
        dial_code: "+55",
        mask: "(99)99999-9999",
    },
    {
        name: "United States",
        flag: "🇺🇸",
        code: "US",
        dial_code: "+1",
        mask: "(999)999-9999",
    },
    {
        name: "Afghanistan",
        flag: "🇦🇫",
        code: "AF",
        dial_code: "+93",
        mask: "99-999-9999",
    },
    {
        name: "Albania",
        flag: "🇦🇱",
        code: "AL",
        dial_code: "+355",
        mask: "(999)999-999",
    },
    {
        name: "Algeria",
        flag: "🇩🇿",
        code: "DZ",
        dial_code: "+213",
        mask: "99-999-9999",
    },
    {
        name: "American Samoa",
        flag: "🇦🇸",
        code: "AS",
        dial_code: "+1684",
        mask: "999-9999",
    },
    {
        name: "Andorra",
        flag: "🇦🇩",
        code: "AD",
        dial_code: "+376",
        mask: "999-999",
    },
    {
        name: "Angola",
        flag: "🇦🇴",
        code: "AO",
        dial_code: "+244",
        mask: "(999)999-999",
    },
    {
        name: "Anguilla",
        flag: "🇦🇮",
        code: "AI",
        dial_code: "+1264",
        mask: "999-9999",
    },
    {
        name: "Antarctica",
        flag: "🇦🇶",
        code: "AQ",
        dial_code: "+672",
        mask: "199-999",
    },
    {
        name: "Antigua and Barbuda",
        flag: "🇦🇬",
        code: "AG",
        dial_code: "+1268",
        mask: "999-9999",
    },
    {
        name: "Argentina",
        flag: "🇦🇷",
        code: "AR",
        dial_code: "+54",
        mask: "(999)999-9999",
    },
    {
        name: "Armenia",
        flag: "🇦🇲",
        code: "AM",
        dial_code: "+374",
        mask: "99-999-999",
    },
    {
        name: "Aruba",
        flag: "🇦🇼",
        code: "AW",
        dial_code: "+297",
        mask: "999-9999",
    },
    {
        name: "Australia",
        flag: "🇦🇺",
        code: "AU",
        dial_code: "+61",
        mask: "9-9999-9999",
    },
    {
        name: "Austria",
        flag: "🇦🇹",
        code: "AT",
        dial_code: "+43",
        mask: "(999)999-9999",
    },
    {
        name: "Azerbaijan",
        flag: "🇦🇿",
        code: "AZ",
        dial_code: "+994",
        mask: "99-999-99-99",
    },
    {
        name: "Bahamas",
        flag: "🇧🇸",
        code: "BS",
        dial_code: "+1242",
        mask: "999-9999",
    },
    {
        name: "Bahrain",
        flag: "🇧🇭",
        code: "BH",
        dial_code: "+973",
        mask: "9999-9999",
    },
    {
        name: "Bangladesh",
        flag: "🇧🇩",
        code: "BD",
        dial_code: "+880",
        mask: "99-999-999",
    },
    {
        name: "Barbados",
        flag: "🇧🇧",
        code: "BB",
        dial_code: "+1246",
        mask: "999-9999",
    },
    {
        name: "Belarus",
        flag: "🇧🇾",
        code: "BY",
        dial_code: "+375",
        mask: "(99)999-99-99",
    },
    {
        name: "Belgium",
        flag: "🇧🇪",
        code: "BE",
        dial_code: "+32",
        mask: "(999)999-999",
    },
    {
        name: "Belize",
        flag: "🇧🇿",
        code: "BZ",
        dial_code: "+501",
        mask: "999-9999",
    },
    {
        name: "Benin",
        flag: "🇧🇯",
        code: "BJ",
        dial_code: "+229",
        mask: "99-99-9999",
    },
    {
        name: "Bermuda",
        flag: "🇧🇲",
        code: "BM",
        dial_code: "+1441",
        mask: "999-9999",
    },
    {
        name: "Bhutan",
        flag: "🇧🇹",
        code: "BT",
        dial_code: "+975",
        mask: "9-999-999",
    },
    {
        name: "Bolivia, Plurinational State of bolivia",
        flag: "🇧🇴",
        code: "BO",
        dial_code: "+591",
        mask: "9-999-9999",
    },
    {
        name: "Bosnia and Herzegovina",
        flag: "🇧🇦",
        code: "BA",
        dial_code: "+387",
        mask: "99-9999",
    },
    {
        name: "Botswana",
        flag: "🇧🇼",
        code: "BW",
        dial_code: "+267",
        mask: "99-999-999",
    },
    {
        name: "British Indian Ocean Territory",
        flag: "🇮🇴",
        code: "IO",
        dial_code: "+246",
        mask: "999-9999",
    },
    {
        name: "Brunei Darussalam",
        flag: "🇧🇳",
        code: "BN",
        dial_code: "+673",
        mask: "999-9999",
    },
    {
        name: "Bulgaria",
        flag: "🇧🇬",
        code: "BG",
        dial_code: "+359",
        mask: "(999)999-999",
    },
    {
        name: "Burkina Faso",
        flag: "🇧🇫",
        code: "BF",
        dial_code: "+226",
        mask: "99-99-9999",
    },
    {
        name: "Burundi",
        flag: "🇧🇮",
        code: "BI",
        dial_code: "+257",
        mask: "99-99-9999",
    },
    {
        name: "Cambodia",
        flag: "🇰🇭",
        code: "KH",
        dial_code: "+855",
        mask: "99-999-999",
    },
    {
        name: "Cameroon",
        flag: "🇨🇲",
        code: "CM",
        dial_code: "+237",
        mask: "9999-9999",
    },
    {
        name: "Canada",
        flag: "🇨🇦",
        code: "CA",
        dial_code: "+1",
        mask: "(999)999-9999",
    },
    {
        name: "Cape Verde",
        flag: "🇨🇻",
        code: "CV",
        dial_code: "+238",
        mask: "(999)99-99",
    },
    {
        name: "Cayman Islands",
        flag: "🇰🇾",
        code: "KY",
        dial_code: "+345",
        mask: "999-9999",
    },
    {
        name: "Central African Republic",
        flag: "🇨🇫",
        code: "CF",
        dial_code: "+236",
        mask: "99-99-9999",
    },
    {
        name: "Chad",
        flag: "🇹🇩",
        code: "TD",
        dial_code: "+235",
        mask: "99-99-99-99",
    },
    {
        name: "Chile",
        flag: "🇨🇱",
        code: "CL",
        dial_code: "+56",
        mask: "9-9999-9999",
    },
    {
        name: "China",
        flag: "🇨🇳",
        code: "CN",
        dial_code: "+86",
        mask: "99-99999-99999",
    },
    {
        name: "Colombia",
        flag: "🇨🇴",
        code: "CO",
        dial_code: "+57",
        mask: "(999)999-9999",
    },
    {
        name: "Comoros",
        flag: "🇰🇲",
        code: "KM",
        dial_code: "+269",
        mask: "99-99999",
    },
    {
        name: "Congo",
        flag: "🇨🇬",
        code: "CG",
        dial_code: "+242",
        mask: "99-999-9999",
    },
    {
        name: "Congo, The Democratic Republic of the Congo",
        flag: "🇨🇩",
        code: "CD",
        dial_code: "+243",
        mask: "(999)999-999",
    },
    {
        name: "Cook Islands",
        flag: "🇨🇰",
        code: "CK",
        dial_code: "+682",
        mask: "99-999",
    },
    {
        name: "Costa Rica",
        flag: "🇨🇷",
        code: "CR",
        dial_code: "+506",
        mask: "9999-9999",
    },
    {
        name: "Cote d'Ivoire",
        flag: "🇨🇮",
        code: "CI",
        dial_code: "+225",
        mask: "99-999-999",
    },
    {
        name: "Croatia",
        flag: "🇭🇷",
        code: "HR",
        dial_code: "+385",
        mask: "99-999-999",
    },
    {
        name: "Cuba",
        flag: "🇨🇺",
        code: "CU",
        dial_code: "+53",
        mask: "9-999-9999",
    },
    {
        name: "Cyprus",
        flag: "🇨🇾",
        code: "CY",
        dial_code: "+357",
        mask: "99-999-999",
    },
    {
        name: "Czech Republic",
        flag: "🇨🇿",
        code: "CZ",
        dial_code: "+420",
        mask: "(999)999-999",
    },
    {
        name: "Denmark",
        flag: "🇩🇰",
        code: "DK",
        dial_code: "+45",
        mask: "99-99-99-99",
    },
    {
        name: "Djibouti",
        flag: "🇩🇯",
        code: "DJ",
        dial_code: "+253",
        mask: "99-99-99-99",
    },
    {
        name: "Dominica",
        flag: "🇩🇲",
        code: "DM",
        dial_code: "+1767",
        mask: "999-9999",
    },
    {
        name: "Dominican Republic",
        flag: "🇩🇴",
        code: "DO",
        dial_code: "+1849",
        mask: "999-9999",
    },
    {
        name: "Ecuador",
        flag: "🇪🇨",
        code: "EC",
        dial_code: "+593",
        mask: "9-999-9999",
    },
    {
        name: "Egypt",
        flag: "🇪🇬",
        code: "EG",
        dial_code: "+20",
        mask: "(999)999-9999",
    },
    {
        name: "El Salvador",
        flag: "🇸🇻",
        code: "SV",
        dial_code: "+503",
        mask: "99-99-9999",
    },
    {
        name: "Equatorial Guinea",
        flag: "🇬🇶",
        code: "GQ",
        dial_code: "+240",
        mask: "99-999-9999",
    },
    {
        name: "Eritrea",
        flag: "🇪🇷",
        code: "ER",
        dial_code: "+291",
        mask: "9-999-999",
    },
    {
        name: "Estonia",
        flag: "🇪🇪",
        code: "EE",
        dial_code: "+372",
        mask: "999-9999",
    },
    {
        name: "Ethiopia",
        flag: "🇪🇹",
        code: "ET",
        dial_code: "+251",
        mask: "99-999-9999",
    },
    {
        name: "Falkland Islands (Malvinas)",
        flag: "🇫🇰",
        code: "FK",
        dial_code: "+500",
        mask: "99999",
    },
    {
        name: "Faroe Islands",
        flag: "🇫🇴",
        code: "FO",
        dial_code: "+298",
        mask: "999-999",
    },
    {
        name: "Fiji",
        flag: "🇫🇯",
        code: "FJ",
        dial_code: "+679",
        mask: "99-99999",
    },
    {
        name: "Finland",
        flag: "🇫🇮",
        code: "FI",
        dial_code: "+358",
        mask: "(999)999-99-99",
    },
    {
        name: "France",
        flag: "🇫🇷",
        code: "FR",
        dial_code: "+33",
        mask: "(999)999-999",
    },
    {
        name: "French Guiana",
        flag: "🇬🇫",
        code: "GF",
        dial_code: "+594",
        mask: "99999-9999",
    },
    {
        name: "French Polynesia",
        flag: "🇵🇫",
        code: "PF",
        dial_code: "+689",
        mask: "99-99-99",
    },
    {
        name: "Gabon",
        flag: "🇬🇦",
        code: "GA",
        dial_code: "+241",
        mask: "9-99-99-99",
    },
    {
        name: "Gambia",
        flag: "🇬🇲",
        code: "GM",
        dial_code: "+220",
        mask: "(999)99-99",
    },
    {
        name: "Georgia",
        flag: "🇬🇪",
        code: "GE",
        dial_code: "+995",
        mask: "(999)999-999",
    },
    {
        name: "Germany",
        flag: "🇩🇪",
        code: "DE",
        dial_code: "+49",
        mask: "999-999",
    },
    {
        name: "Ghana",
        flag: "🇬🇭",
        code: "GH",
        dial_code: "+233",
        mask: "(999)999-999",
    },
    {
        name: "Gibraltar",
        flag: "🇬🇮",
        code: "GI",
        dial_code: "+350",
        mask: "999-99999",
    },
    {
        name: "Greece",
        flag: "🇬🇷",
        code: "GR",
        dial_code: "+30",
        mask: "(999)999-9999",
    },
    {
        name: "Greenland",
        flag: "🇬🇱",
        code: "GL",
        dial_code: "+299",
        mask: "99-99-99",
    },
    {
        name: "Grenada",
        flag: "🇬🇩",
        code: "GD",
        dial_code: "+1473",
        mask: "999-9999",
    },
    {
        name: "Guam",
        flag: "🇬🇺",
        code: "GU",
        dial_code: "+1671",
        mask: "999-9999",
    },
    {
        name: "Guatemala",
        flag: "🇬🇹",
        code: "GT",
        dial_code: "+502",
        mask: "9-999-9999",
    },
    {
        name: "Guernsey",
        flag: "🇬🇬",
        code: "GG",
        dial_code: "+44",
    },
    {
        name: "Guinea",
        flag: "🇬🇳",
        code: "GN",
        dial_code: "+224",
        mask: "99-999-999",
    },
    {
        name: "Guinea-Bissau",
        flag: "🇬🇼",
        code: "GW",
        dial_code: "+245",
        mask: "9-999999",
    },
    {
        name: "Guyana",
        flag: "🇬🇾",
        code: "GY",
        dial_code: "+592",
        mask: "999-9999",
    },
    {
        name: "Haiti",
        flag: "🇭🇹",
        code: "HT",
        dial_code: "+509",
        mask: "99-99-9999",
    },
    {
        name: "Holy See (Vatican City State)",
        flag: "🇻🇦",
        code: "VA",
        dial_code: "+379",
        mask: "6-698-99999",
    },
    {
        name: "Honduras",
        flag: "🇭🇳",
        code: "HN",
        dial_code: "+504",
        mask: "9999-9999",
    },
    {
        name: "Hong Kong",
        flag: "🇭🇰",
        code: "HK",
        dial_code: "+852",
        mask: "9999-9999",
    },
    {
        name: "Hungary",
        flag: "🇭🇺",
        code: "HU",
        dial_code: "+36",
        mask: "(999)999-999",
    },
    {
        name: "Iceland",
        flag: "🇮🇸",
        code: "IS",
        dial_code: "+354",
        mask: "999-9999",
    },
    {
        name: "India",
        flag: "🇮🇳",
        code: "IN",
        dial_code: "+91",
        mask: "(9999)999-999",
    },
    {
        name: "Indonesia",
        flag: "🇮🇩",
        code: "ID",
        dial_code: "+62",
        mask: "(899)999-99-999",
    },
    {
        name: "Iran, Islamic Republic of Persian Gulf",
        flag: "🇮🇷",
        code: "IR",
        dial_code: "+98",
        mask: "(999)999-9999",
    },
    {
        name: "Iraq",
        flag: "🇮🇶",
        code: "IQ",
        dial_code: "+964",
        mask: "(999)999-9999",
    },
    {
        name: "Ireland",
        flag: "🇮🇪",
        code: "IE",
        dial_code: "+353",
        mask: "(999)999-999",
    },
    {
        name: "Israel",
        flag: "🇮🇱",
        code: "IL",
        dial_code: "+972",
        mask: "9-999-9999",
    },
    {
        name: "Italy",
        flag: "🇮🇹",
        code: "IT",
        dial_code: "+39",
        mask: "(999)9999-999",
    },
    {
        name: "Jamaica",
        flag: "🇯🇲",
        code: "JM",
        dial_code: "+1876",
        mask: "999-9999",
    },
    {
        name: "Japan",
        flag: "🇯🇵",
        code: "JP",
        dial_code: "+81",
        mask: "(999)999-999",
    },
    {
        name: "Jersey",
        flag: "🇯🇪",
        code: "JE",
        dial_code: "+44",
    },
    {
        name: "Jordan",
        flag: "🇯🇴",
        code: "JO",
        dial_code: "+962",
        mask: "9-9999-9999",
    },
    {
        name: "Kazakhstan",
        flag: "🇰🇿",
        code: "KZ",
        dial_code: "+7",
        mask: "(799)999-99-99",
    },
    {
        name: "Kenya",
        flag: "🇰🇪",
        code: "KE",
        dial_code: "+254",
        mask: "999-999999",
    },
    {
        name: "Kiribati",
        flag: "🇰🇮",
        code: "KI",
        dial_code: "+686",
        mask: "99-999",
    },
    {
        name: "Korea, Democratic People's Republic of Korea",
        flag: "🇰🇵",
        code: "KP",
        dial_code: "+850",
        mask: "9999-9999999999999",
    },
    {
        name: "Korea, Republic of South Korea",
        flag: "🇰🇷",
        code: "KR",
        dial_code: "+82",
        mask: "99-999-9999",
    },
    {
        name: "Kuwait",
        flag: "🇰🇼",
        code: "KW",
        dial_code: "+965",
        mask: "9999-9999",
    },
    {
        name: "Kyrgyzstan",
        flag: "🇰🇬",
        code: "KG",
        dial_code: "+996",
        mask: "(999)999-999",
    },
    {
        name: "Laos",
        flag: "🇱🇦",
        code: "LA",
        dial_code: "+856",
        mask: "99-999-999",
    },
    {
        name: "Latvia",
        flag: "🇱🇻",
        code: "LV",
        dial_code: "+371",
        mask: "99-999-999",
    },
    {
        name: "Lebanon",
        flag: "🇱🇧",
        code: "LB",
        dial_code: "+961",
        mask: "9-999-999",
    },
    {
        name: "Lesotho",
        flag: "🇱🇸",
        code: "LS",
        dial_code: "+266",
        mask: "9-999-9999",
    },
    {
        name: "Liberia",
        flag: "🇱🇷",
        code: "LR",
        dial_code: "+231",
        mask: "99-999-999",
    },
    {
        name: "Libyan Arab Jamahiriya",
        flag: "🇱🇾",
        code: "LY",
        dial_code: "+218",
        mask: "21-999-9999",
    },
    {
        name: "Liechtenstein",
        flag: "🇱🇮",
        code: "LI",
        dial_code: "+423",
        mask: "(999)999-9999",
    },
    {
        name: "Lithuania",
        flag: "🇱🇹",
        code: "LT",
        dial_code: "+370",
        mask: "(999)99-999",
    },
    {
        name: "Luxembourg",
        flag: "🇱🇺",
        code: "LU",
        dial_code: "+352",
        mask: "(999)999-999",
    },
    {
        name: "Macao",
        flag: "🇲🇴",
        code: "MO",
        dial_code: "+853",
        mask: "9999-9999",
    },
    {
        name: "Macedonia",
        flag: "🇲🇰",
        code: "MK",
        dial_code: "+389",
        mask: "99-999-999",
    },
    {
        name: "Madagascar",
        flag: "🇲🇬",
        code: "MG",
        dial_code: "+261",
        mask: "99-99-99999",
    },
    {
        name: "Malawi",
        flag: "🇲🇼",
        code: "MW",
        dial_code: "+265",
        mask: "9-9999-9999",
    },
    {
        name: "Malaysia",
        flag: "🇲🇾",
        code: "MY",
        dial_code: "+60",
        mask: "9-999-999",
    },
    {
        name: "Maldives",
        flag: "🇲🇻",
        code: "MV",
        dial_code: "+960",
        mask: "999-9999",
    },
    {
        name: "Mali",
        flag: "🇲🇱",
        code: "ML",
        dial_code: "+223",
        mask: "99-99-9999",
    },
    {
        name: "Malta",
        flag: "🇲🇹",
        code: "MT",
        dial_code: "+356",
        mask: "9999-9999",
    },
    {
        name: "Marshall Islands",
        flag: "🇲🇭",
        code: "MH",
        dial_code: "+692",
        mask: "999-9999",
    },
    {
        name: "Martinique",
        flag: "🇲🇶",
        code: "MQ",
        dial_code: "+596",
        mask: "(999)99-99-99",
    },
    {
        name: "Mauritania",
        flag: "🇲🇷",
        code: "MR",
        dial_code: "+222",
        mask: "99-99-9999",
    },
    {
        name: "Mauritius",
        flag: "🇲🇺",
        code: "MU",
        dial_code: "+230",
        mask: "999-9999",
    },
    {
        name: "Mexico",
        flag: "🇲🇽",
        code: "MX",
        dial_code: "+52",
        mask: "99-99-9999",
    },
    {
        name: "Micronesia, Federated States of Micronesia",
        flag: "🇫🇲",
        code: "FM",
        dial_code: "+691",
        mask: "999-9999",
    },
    {
        name: "Moldova",
        flag: "🇲🇩",
        code: "MD",
        dial_code: "+373",
        mask: "9999-9999",
    },
    {
        name: "Monaco",
        flag: "🇲🇨",
        code: "MC",
        dial_code: "+377",
        mask: "99-999-999",
    },
    {
        name: "Mongolia",
        flag: "🇲🇳",
        code: "MN",
        dial_code: "+976",
        mask: "99-99-9999",
    },
    {
        name: "Montenegro",
        flag: "🇲🇪",
        code: "ME",
        dial_code: "+382",
        mask: "99-999-999",
    },
    {
        name: "Montserrat",
        flag: "🇲🇸",
        code: "MS",
        dial_code: "+1664",
        mask: "999-9999",
    },
    {
        name: "Morocco",
        flag: "🇲🇦",
        code: "MA",
        dial_code: "+212",
        mask: "99-9999-999",
    },
    {
        name: "Mozambique",
        flag: "🇲🇿",
        code: "MZ",
        dial_code: "+258",
        mask: "99-999-999",
    },
    {
        name: "Myanmar",
        flag: "🇲🇲",
        code: "MM",
        dial_code: "+95",
        mask: "999-999",
    },
    {
        name: "Namibia",
        flag: "🇳🇦",
        code: "NA",
        dial_code: "+264",
        mask: "99-999-9999",
    },
    {
        name: "Nauru",
        flag: "🇳🇷",
        code: "NR",
        dial_code: "+674",
        mask: "999-9999",
    },
    {
        name: "Nepal",
        flag: "🇳🇵",
        code: "NP",
        dial_code: "+977",
        mask: "99-999-999",
    },
    {
        name: "Netherlands",
        flag: "🇳🇱",
        code: "NL",
        dial_code: "+31",
        mask: "99-999-9999",
    },
    {
        name: "Netherlands Antilles",
        flag: "",
        code: "AN",
        dial_code: "+599",
        mask: "9999-9999",
    },
    {
        name: "New Caledonia",
        flag: "🇳🇨",
        code: "NC",
        dial_code: "+687",
        mask: "99-9999",
    },
    {
        name: "New Zealand",
        flag: "🇳🇿",
        code: "NZ",
        dial_code: "+64",
        mask: "(999)999-9999",
    },
    {
        name: "Nicaragua",
        flag: "🇳🇮",
        code: "NI",
        dial_code: "+505",
        mask: "9999-9999",
    },
    {
        name: "Niger",
        flag: "🇳🇪",
        code: "NE",
        dial_code: "+227",
        mask: "99-99-9999",
    },
    {
        name: "Nigeria",
        flag: "🇳🇬",
        code: "NG",
        dial_code: "+234",
        mask: "(999)999-9999",
    },
    {
        name: "Niue",
        flag: "🇳🇺",
        code: "NU",
        dial_code: "+683",
        mask: "9999",
    },
    {
        name: "Norfolk Island",
        flag: "🇳🇫",
        code: "NF",
        dial_code: "+672",
        mask: "399-999",
    },
    {
        name: "Northern Mariana Islands",
        flag: "🇲🇵",
        code: "MP",
        dial_code: "+1670",
        mask: "999-9999",
    },
    {
        name: "Norway",
        flag: "🇳🇴",
        code: "NO",
        dial_code: "+47",
        mask: "(999)99-999",
    },
    {
        name: "Oman",
        flag: "🇴🇲",
        code: "OM",
        dial_code: "+968",
        mask: "99-999-999",
    },
    {
        name: "Pakistan",
        flag: "🇵🇰",
        code: "PK",
        dial_code: "+92",
        mask: "(999)999-9999",
    },
    {
        name: "Palau",
        flag: "🇵🇼",
        code: "PW",
        dial_code: "+680",
        mask: "999-9999",
    },
    {
        name: "Palestinian Territory, Occupied",
        flag: "🇵🇸",
        code: "PS",
        dial_code: "+970",
        mask: "99-999-9999",
    },
    {
        name: "Panama",
        flag: "🇵🇦",
        code: "PA",
        dial_code: "+507",
        mask: "999-9999",
    },
    {
        name: "Papua New Guinea",
        flag: "🇵🇬",
        code: "PG",
        dial_code: "+675",
        mask: "(999)99-999",
    },
    {
        name: "Paraguay",
        flag: "🇵🇾",
        code: "PY",
        dial_code: "+595",
        mask: "(999)999-999",
    },
    {
        name: "Peru",
        flag: "🇵🇪",
        code: "PE",
        dial_code: "+51",
        mask: "(999)999-999",
    },
    {
        name: "Philippines",
        flag: "🇵🇭",
        code: "PH",
        dial_code: "+63",
        mask: "(999)999-9999",
    },
    {
        name: "Poland",
        flag: "🇵🇱",
        code: "PL",
        dial_code: "+48",
        mask: "(999)999-999",
    },
    {
        name: "Portugal",
        flag: "🇵🇹",
        code: "PT",
        dial_code: "+351",
        mask: "99-999-9999",
    },
    {
        name: "Puerto Rico",
        flag: "🇵🇷",
        code: "PR",
        dial_code: "+1939",
    },
    {
        name: "Qatar",
        flag: "🇶🇦",
        code: "QA",
        dial_code: "+974",
        mask: "9999-9999",
    },
    {
        name: "Romania",
        flag: "🇷🇴",
        code: "RO",
        dial_code: "+40",
        mask: "99-999-9999",
    },
    {
        name: "Russia",
        flag: "🇷🇺",
        code: "RU",
        dial_code: "+7",
        mask: "(999)999-99-99",
    },
    {
        name: "Rwanda",
        flag: "🇷🇼",
        code: "RW",
        dial_code: "+250",
        mask: "(999)999-999",
    },
    {
        name: "Reunion",
        flag: "🇷🇪",
        code: "RE",
        dial_code: "+262",
        mask: "99999-9999",
    },
    {
        name: "Saint Helena, Ascension and Tristan Da Cunha",
        flag: "🇸🇭",
        code: "SH",
        dial_code: "+290",
        mask: "9999",
    },
    {
        name: "Saint Kitts and Nevis",
        flag: "🇰🇳",
        code: "KN",
        dial_code: "+1869",
        mask: "999-9999",
    },
    {
        name: "Saint Lucia",
        flag: "🇱🇨",
        code: "LC",
        dial_code: "+1758",
        mask: "999-9999",
    },
    {
        name: "Saint Vincent and the Grenadines",
        flag: "🇻🇨",
        code: "VC",
        dial_code: "+1784",
        mask: "999-9999",
    },
    {
        name: "Samoa",
        flag: "🇼🇸",
        code: "WS",
        dial_code: "+685",
        mask: "99-9999",
    },
    {
        name: "San Marino",
        flag: "🇸🇲",
        code: "SM",
        dial_code: "+378",
        mask: "9999-999999",
    },
    {
        name: "Sao Tome and Principe",
        flag: "🇸🇹",
        code: "ST",
        dial_code: "+239",
        mask: "99-99999",
    },
    {
        name: "Saudi Arabia",
        flag: "🇸🇦",
        code: "SA",
        dial_code: "+966",
        mask: "9-999-9999",
    },
    {
        name: "Senegal",
        flag: "🇸🇳",
        code: "SN",
        dial_code: "+221",
        mask: "99-999-9999",
    },
    {
        name: "Serbia",
        flag: "🇷🇸",
        code: "RS",
        dial_code: "+381",
        mask: "99-999-9999",
    },
    {
        name: "Seychelles",
        flag: "🇸🇨",
        code: "SC",
        dial_code: "+248",
        mask: "9-999-999",
    },
    {
        name: "Sierra Leone",
        flag: "🇸🇱",
        code: "SL",
        dial_code: "+232",
        mask: "99-999999",
    },
    {
        name: "Singapore",
        flag: "🇸🇬",
        code: "SG",
        dial_code: "+65",
        mask: "9999-9999",
    },
    {
        name: "Slovakia",
        flag: "🇸🇰",
        code: "SK",
        dial_code: "+421",
        mask: "(999)999-999",
    },
    {
        name: "Slovenia",
        flag: "🇸🇮",
        code: "SI",
        dial_code: "+386",
        mask: "99-999-999",
    },
    {
        name: "Solomon Islands",
        flag: "🇸🇧",
        code: "SB",
        dial_code: "+677",
        mask: "99999",
    },
    {
        name: "Somalia",
        flag: "🇸🇴",
        code: "SO",
        dial_code: "+252",
        mask: "9-999-999",
    },
    {
        name: "South Africa",
        flag: "🇿🇦",
        code: "ZA",
        dial_code: "+27",
        mask: "99-999-9999",
    },
    {
        name: "South Sudan",
        flag: "🇸🇸",
        code: "SS",
        dial_code: "+211",
        mask: "99-999-9999",
    },
    {
        name: "Spain",
        flag: "🇪🇸",
        code: "ES",
        dial_code: "+34",
        mask: "(999)999-999",
    },
    {
        name: "Sri Lanka",
        flag: "🇱🇰",
        code: "LK",
        dial_code: "+94",
        mask: "99-999-9999",
    },
    {
        name: "Sudan",
        flag: "🇸🇩",
        code: "SD",
        dial_code: "+249",
        mask: "99-999-9999",
    },
    {
        name: "Suriname",
        flag: "🇸🇷",
        code: "SR",
        dial_code: "+597",
        mask: "999-999",
    },
    {
        name: "Eswatini",
        flag: "🇸🇿",
        code: "SZ",
        dial_code: "+268",
        mask: "99-99-9999",
    },
    {
        name: "Sweden",
        flag: "🇸🇪",
        code: "SE",
        dial_code: "+46",
        mask: "99-999-9999",
    },
    {
        name: "Switzerland",
        flag: "🇨🇭",
        code: "CH",
        dial_code: "+41",
        mask: "99-999-9999",
    },
    {
        name: "Syrian Arab Republic",
        flag: "🇸🇾",
        code: "SY",
        dial_code: "+963",
        mask: "99-9999-999",
    },
    {
        name: "Taiwan",
        flag: "🇹🇼",
        code: "TW",
        dial_code: "+886",
        mask: "9999-9999",
    },
    {
        name: "Tajikistan",
        flag: "🇹🇯",
        code: "TJ",
        dial_code: "+992",
        mask: "99-999-9999",
    },
    {
        name: "Tanzania, United Republic of Tanzania",
        flag: "🇹🇿",
        code: "TZ",
        dial_code: "+255",
        mask: "99-999-9999",
    },
    {
        name: "Thailand",
        flag: "🇹🇭",
        code: "TH",
        dial_code: "+66",
        mask: "99-999-999",
    },
    {
        name: "Timor-Leste",
        flag: "🇹🇱",
        code: "TL",
        dial_code: "+670",
        mask: "789-99999",
    },
    {
        name: "Togo",
        flag: "🇹🇬",
        code: "TG",
        dial_code: "+228",
        mask: "99-999-999",
    },
    {
        name: "Tokelau",
        flag: "🇹🇰",
        code: "TK",
        dial_code: "+690",
        mask: "9999",
    },
    {
        name: "Tonga",
        flag: "🇹🇴",
        code: "TO",
        dial_code: "+676",
        mask: "99999",
    },
    {
        name: "Trinidad and Tobago",
        flag: "🇹🇹",
        code: "TT",
        dial_code: "+1868",
        mask: "999-9999",
    },
    {
        name: "Tunisia",
        flag: "🇹🇳",
        code: "TN",
        dial_code: "+216",
        mask: "99-999-999",
    },
    {
        name: "Turkey",
        flag: "🇹🇷",
        code: "TR",
        dial_code: "+90",
        mask: "(999)999-9999",
    },
    {
        name: "Turkmenistan",
        flag: "🇹🇲",
        code: "TM",
        dial_code: "+993",
        mask: "9-999-9999",
    },
    {
        name: "Turks and Caicos Islands",
        flag: "🇹🇨",
        code: "TC",
        dial_code: "+1649",
        mask: "999-9999",
    },
    {
        name: "Tuvalu",
        flag: "🇹🇻",
        code: "TV",
        dial_code: "+688",
        mask: "29999",
    },
    {
        name: "Uganda",
        flag: "🇺🇬",
        code: "UG",
        dial_code: "+256",
        mask: "(999)999-999",
    },
    {
        name: "Ukraine",
        flag: "🇺🇦",
        code: "UA",
        dial_code: "+380",
        mask: "(99)999-99-99",
    },
    {
        name: "United Arab Emirates",
        flag: "🇦🇪",
        code: "AE",
        dial_code: "+971",
        mask: "9-999-9999",
    },
    {
        name: "United Kingdom",
        flag: "🇬🇧",
        code: "GB",
        dial_code: "+44",
    },
    {
        name: "Uruguay",
        flag: "🇺🇾",
        code: "UY",
        dial_code: "+598",
        mask: "9-999-99-99",
    },
    {
        name: "Uzbekistan",
        flag: "🇺🇿",
        code: "UZ",
        dial_code: "+998",
        mask: "99-999-9999",
    },
    {
        name: "Vanuatu",
        flag: "🇻🇺",
        code: "VU",
        dial_code: "+678",
        mask: "99999",
    },
    {
        name: "Venezuela, Bolivarian Republic of Venezuela",
        flag: "🇻🇪",
        code: "VE",
        dial_code: "+58",
        mask: "(999)999-9999",
    },
    {
        name: "Vietnam",
        flag: "🇻🇳",
        code: "VN",
        dial_code: "+84",
        mask: "(999)9999-999",
    },
    {
        name: "Virgin Islands, British",
        flag: "🇻🇬",
        code: "VG",
        dial_code: "+1284",
        mask: "999-9999",
    },
    {
        name: "Virgin Islands, U.S.",
        flag: "🇻🇮",
        code: "VI",
        dial_code: "+1340",
        mask: "999-9999",
    },
    {
        name: "Wallis and Futuna",
        flag: "🇼🇫",
        code: "WF",
        dial_code: "+681",
        mask: "99-9999",
    },
    {
        name: "Yemen",
        flag: "🇾🇪",
        code: "YE",
        dial_code: "+967",
        mask: "99-999-999",
    },
    {
        name: "Zambia",
        flag: "🇿🇲",
        code: "ZM",
        dial_code: "+260",
        mask: "99-999-9999",
    },
    {
        name: "Zimbabwe",
        flag: "🇿🇼",
        code: "ZW",
        dial_code: "+263",
        mask: "9-999999",
    },
].map((elem) => {
    return {
        ...elem,
        mask: elem.mask ? elem.mask?.replaceAll('9', '#') : "##########",
        label: `${elem.flag} ${elem.name} (${elem.dial_code})`
    }
});