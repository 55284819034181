<template>
  <div>
    <ModalComponent id="subscription_modal" title="Psiapp +">
      <template v-slot:body>
        <div class="card bg-info-soft p-6">
          <div class="d-flex justify-content-center align-items-center">
            <img src="@/assets/img/icon_psiapp_plus.png" alt="" height="100" />
          </div>
        </div>
        <p class="text-header">Chegou o Psiapp+!</p>
        <p class="text-paragraph">
          {{ plan?.description }}
        </p>

        <hr />

        <p class="text-header">Principais benefícios</p>
        <div
          class="d-flex align-items-center mb-3 pb-3"
          :class="{ 'border-bottom': index + 1 != features.length }"
          v-for="(item, index) in features"
          :key="index"
        >
          <i class="fa-solid fa-check-circle text-primary fa-lg me-2" />
          <p class="text-paragraph mb-0">{{ item }}</p>
        </div>

        <p class="text-caption text-muted mb-4">
          Assinatura do Psiapp+ não garante o agendamento de novas sessões.
        </p>
        <button
          class="btn btn-secondary w-100 text-primary"
          @click="handleSubscribe"
        >
          Quero fazer parte
        </button>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import SubscriptionModalController from "./SubscriptionModalController";
export default SubscriptionModalController;
</script>
