<template>
  <div>
    <ModalComponent id="credit_cards_modal" title="Informações de pagamento">
      <template v-slot:body>
        <div
          @click="handleSelectCard(item)"
          v-for="(item, index) in items"
          :key="index"
          class="border rounded-4 p-4 d-flex justify-content-between cursor-pointer mb-3"
        >
          <div>
            <div class="d-flex align-items-center mb-3">
              <img src="@/assets/img/wallet_icon.svg" alt="" />
              <p class="text-subtitle mb-0 ms-2">Cartão de crédito</p>
            </div>
            <p class="text-paragraph">•••• •••• •••• {{ item.last_digits }}</p>
            <div
              class="d-flex align-items-center"
              @click="handleDeleteCard(item)"
            >
              <img
                src="@/assets/img/trash_icon.svg"
                alt=""
                class="text-muted"
              />
              <p class="text-subtitle text-muted mb-0 ms-2">Remover</p>
            </div>
          </div>
          <i
            class="fa-solid fa-check-circle text-secondary display-5"
            v-if="item?.card_id == userSession?.favorite_card?.card_id"
          />
          <i class="fa-regular fa-circle text-muted display-5" v-else />
        </div>

        <div
          @click="handleAddCreditCard"
          class="border rounded-4 py-3 px-4 d-flex justify-content-between align-items-center cursor-pointer mb-4"
        >
          <div class="d-flex align-items-center">
            <img src="@/assets/img/wallet_icon.svg" alt="" />
            <p class="text-subtitle mb-0 ms-2">Adicionar novo cartão</p>
          </div>
          <i class="fa-solid fa-chevron-right text-primary"></i>
        </div>

        <button
          class="btn btn-secondary w-100 text-primary"
          @click="closeModal"
        >
          Concluir
        </button>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import CreditCardsModalController from "./CreditCardsModalController.ts";
export default CreditCardsModalController;
</script>
