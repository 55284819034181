
interface String {
    capitalizeFirst(): String;
    limitString(): String;
    clear(): String;
    formatCPF(): String;
    formatPhone(): String;
    toPattern(): String
}
String.prototype.capitalizeFirst = function () {
    let res = this
    try {
        res = this.charAt(0).toUpperCase() + this.slice(1);
    } catch (e) {
        console.log(e)
    } finally {
        return res
    }
}
String.prototype.limitString = function (limit = 40) {
    let res = this
    try {
        res = this.length >= limit ? `${this.substr(0, limit - 1)}...` : this
    } catch (e) {
        console.log('erro', e)
        return res
    }
    finally {
        return res
    }
}
String.prototype.clear = function () {
    let res = this
    try {
        res = this.replace(/[^\w\s]/gi, '').replace(' ', '').replace(/[_-]/g, "")
    } catch (e) {
        return res
    }
    finally {
        return res
    }
}
String.prototype.formatCPF = function () {
    let res = this
    try {
        // Remove qualquer caractere que não seja número
        res = res.replace(/\D/g, "");
        // Aplica a formatação
        res = res.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } catch (error) {
        return res

    } finally {
        return res
    }
}
String.prototype.formatPhone = function () {
    let res = this
    try {
        const phoneCleaned = res.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

        // Define os padrões de máscara com base no tamanho da string
        const patternWithNine = /^(\d{2})(\d{5})(\d{4})$/;
        const patternWithNoNine = /^(\d{2})(\d{4})(\d{4})$/;

        // Aplica a máscara de acordo com o tamanho da string
        const formattedPhone = phoneCleaned.replace(
            phoneCleaned.length === 11 ? patternWithNine : patternWithNoNine,
            phoneCleaned.length === 11 ? "($1) $2-$3" : "($1) $2-$3"
        );

        return formattedPhone;
    } catch (error) {
        console.log(error)
        return res
    }
}