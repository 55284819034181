import Pusher from 'pusher-js'

export default class WebSocketService {
    private pusher
    private PUSHER_KEY = process.env.VUE_APP_PUSHER_KEY
    private PUSHER_OPTIONS = { cluster: process.env.VUE_APP_PUSHER_CLUSTER }

    constructor() {
        this.pusher = new Pusher(this.PUSHER_KEY, this.PUSHER_OPTIONS);
    }

    subscribe(value: string) {
        setTimeout(() => {
            let state = this.pusher.connection.state;
            console.log("STATUS DO PUSHER:", state);
            if (state != "connected") {
                console.log("DEU RUIM NO PUSHER");
            }
        }, 15000);
        return this.pusher.subscribe(value);
    }

    unsubscribe(value: string) {
        this.pusher.unsubscribe(value)
    }
}
