
import moment from 'moment';
import NetworkService, { Endpoint } from './NetworkService'

export default class SubscriptionService {
    private network
    constructor() {
        this.network = new NetworkService()
    }

    show = (id: String | Number) => {
        return this.network.get(`${Endpoint.subscription}/${id}`);
    };
    prepareSubscriptionInfo = (data: any) => {
        const isActive =
            data?.status !== "incomplete_expired" &&
            data?.status !== "canceled" &&
            data?.status !== "unpaid";
        const subscribeEnabled =
            data?.status == "incomplete_expired" ||
            data?.status == "canceled" ||
            data?.status == "unpaid";

        const isManual = data?.plan ? data?.plan?.gateway === "manual" : null;

        const actionsEnabled = !data?.cancel_at && !isManual;

        const statusLabel = this.getSubscriptionStatus(data.status);

        const subscriptionDate = moment(data?.created_at).format("DD/MM/YYYY");

        const hasCanceled = data?.cancel_at;

        const cancellationDate = hasCanceled ? moment(data.cancel_at).format("DD [de] MMMM") : null;

        const creditCardLast4 = data?.card_info ? `•••• •••• •••• ${data?.card_info?.last4 || data?.card_info?.data[0]?.last4
            }` : null;

        return {
            ...data,
            actionsEnabled,
            subscribeEnabled,
            isManual,
            isActive,
            creditCardLast4,
            statusLabel,
            subscriptionDate,
            cancellationDate,
            hasCanceled,
        };
    };
    getSubscriptionStatus = (status: string) => {
        switch (status) {
            case "active":
                return "Assinante";
            case "trialing":
                return "Período de teste";
            case "past_due":
                return "Em atraso";
            case "incomplete":
                return "Pagamento pendente";
            case "incomplete_expired":
            case "unpaid":
            case "canceled":
            default:
                return "Gratuíto";
        }
    };
    cancelSubscription = (planId: string | number) => {
        return this.network.delete(`${Endpoint.subscription}/${planId}`);
    };
    psiappPlus = () => {
        return this.network.get(`${Endpoint.plans}/psiapp-mais-pro`);
    };
    subscription = (params: any) => {
        return this.network.post(Endpoint.subscription, params);
    };
    update = (id: string | number, params: any) => {
        return this.network.put(`${Endpoint.subscription}/${id}`, params);
    };
}