import { store } from './../../store/index';
import { toggleModal } from "@/utils/Helper";
import { defineComponent } from "vue";

export default defineComponent({
    mounted() {
        this.loading = true
        this.getPlan()
    },
    data() {
        return {
            loading: false,
            plan: null,
            features: []
        }
    },
    methods: {
        async getPlan() {
            try {
                let res = await this.$subscriptionService.psiappPlus();
                // setScreenTitle(res.data.title);
                this.features = res.data.features.split("|");
                this.plan = res.data;
            } catch (error) {
                console.log(
                    "ERRO NO GET PLAN NO PSIAPP PLUS SCREEN",
                    JSON.stringify(error, null, 4)
                );
            } finally {
                this.loading = false;
            }
        },
        handleSubscribe() {
            toggleModal('subscription_modal');
            this.$router.push(this.$paths.psiappPlus);
        },
    },
});