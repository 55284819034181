import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDFpMaS1PMpdCTEKyg7Pb_sy82yPCD43lk",
  authDomain: "autoday-1fc4d.firebaseapp.com",
  projectId: "autoday-1fc4d",
  storageBucket: "autoday-1fc4d.appspot.com",
  messagingSenderId: "10674205549",
  appId: "1:10674205549:web:647223e1eb20563260a064",
  measurementId: "G-CY0FLY7NHV"
};
export function startFirebase() {
  if(process.env.VUE_APP_ENV_NAME == 'PROD') {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
  }
}
