<template>
  <div
    class="position-relative justify-content-center d-flex"
    @mouseover="onMouseOver($event)"
    @mouseleave="onMouseLeave($event)"
  >
    <button
      @click.prevent="clicked()"
      :disabled="loading"
      class="btn w-100 btn-secondary"
      :class="{ 'my-3': marginVertical }"
    >
      <span
        v-if="loading"
        class="spinner-border spinner-border-sm me-2 text-primary"
        role="status"
        aria-hidden="true"
      ></span>
      <span class="fw-bold text-primary">
        {{ label }}
      </span>
    </button>
    <div
      v-if="showError && fieldError"
      class="position-absolute shadow-lg bg-danger-soft rounded text-center"
      style="top: -50px; z-index: 10"
    >
      <p class="popover-header text-danger p-3 mb-0 fw-bold">
        <i class="fa-solid fa-exclamation-circle"></i>
        {{ fieldError }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FormButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    marginVertical: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Field name",
    },
    form: {
      required: false,
      // type: {} as any,
      default: () => null as any,
    },
  },
  computed: {
    fieldError() {
      if (!this.form) return;
      let msg = "";
      try {
        const messages = this.form.$silentErrors?.map((r: any) => r.$message);
        msg = messages.length ? messages[0] : "";
      } catch (e) {
        console.log(e);
        msg = "";
      } finally {
        return msg;
      }
    },
  },
  data() {
    return {
      showError: false,
    };
  },
  methods: {
    clicked() {
      if (this.disabled) return;
      this.$emit("onClick");
    },
    onMouseOver($event: any) {
      this.showError = true;
    },
    onMouseLeave($event: any) {
      this.showError = false;
    },
  },
});
</script>
