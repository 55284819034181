import { store } from '@/store'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import paths from './paths'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: paths.home,
  //   name: 'Home',
  //   alias: '/',
  //   component: () => import('../views/Home/Home.vue')
  //   meta: { requiresAuth: true }
  // },

  {
    path: paths.login,
    name: 'Login',
    component: () => import('../views/Login/Login.vue'),
  },

  {
    alias: paths.register,
    path: `${paths.register}/:step?`,
    name: 'Register',
    component: () => import('../views/Register/Register.vue'),
  },
  {
    path: paths.onHolding,
    name: 'OnHolding',
    component: () => import('../views/OnHolding/OnHolding.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: paths.addBankAccount,
    name: 'AddBankAccount',
    component: () => import('../views/AddBankAccount/AddBankAccount.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: paths.appointments.list,
    name: 'Appointments',
    alias: '/',
    component: () => import('../views/Appointments/Appointments.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.appointments.room}/:id`,
    name: 'AppointmentRoom',
    component: () => import('../views/Appointments/AppointmentRoom/AppointmentRoom.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: `${paths.appointments.detail}/:id`,
    name: 'AppointmentDetail',
    component: () => import('../views/Appointments/AppointmentDetail/AppointmentDetail.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: `${paths.more}/:path?`,
    name: 'More',
    component: () => import('../views/More/More.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: paths.psiappPlus,
    name: 'PsiappPlus',
    component: () => import('../views/PsiappPlus/PsiappPlus.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: paths.not_found,
    name: 'NotFound',
    component: () => import('../views/NotFound/NotFound.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'NotFound'
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = !!store.state.session;
  if (to.meta.requiresAuth && !isLoggedIn) {
    next(paths.login);
  } else {
    const session = store.state.session
    if (to.meta.requiresAuth
      && to.path !== paths.onHolding
      && to.path !== paths.addBankAccount
      && session
      && (!session?.approved || !session?.stripe_id || !session?.charges_enabled)
    ) {
      next(paths.onHolding);
      return
    }
    next();
  }
});

export default router
