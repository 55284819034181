const paths = {
    not_found: '/not-found',
    login: '/login',
    home: '/sessoes',
    register: '/cadastro',
    onHolding: '/finalizar-cadastro',
    addBankAccount: '/cadastrar-informacoes-bancaria',
    appointments: {
        list: '/sessoes',
        room: '/sessoes/sala',
        detail: '/sessoes/detalhes'
    },
    more: '/mais',
    psiappPlus: '/psiapp-plus'
}

export default paths